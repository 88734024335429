export default {
    STORE_KEY: 'a56z0fzrNpl^2',
    //Dev
    //HOST_URL: 'http://ananas.devkot.ru/',
    //API_URL: 'http://ananas.devkot.ru/server/public/api/v1',
    //PUBLIC_URL: 'http://ananas.devkot.ru/server/public/uploads/',

    //Prod
    //HOST_URL: 'https://www.ananas-72.ru/',
    //API_URL: 'https://www.crm.ananas-72.ru/server/public/api/v1',
    //PUBLIC_URL: 'https://www.crm.ananas-72.ru/server/public/uploads/',

    HOST_URL: 'https://www.ananas-72.ru/',
    HOST_CRM_URL: 'https://www.crm.ananas-72.ru/',
    API_URL: 'https://ananasflower.ru/server/public/api/v1',
    WS_URL: 'wss://194.58.98.186:443/',
    PUBLIC_URL: 'https://ananasflower.ru/server/public/uploads/',

 
    //STORAGE_URL: '',
   
    getHeaders: function () {
      return {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
      }
    }
  };