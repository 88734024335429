export const inArray = (_arr, _val) => {
    let f = false;
    if(_arr ) _arr.map((item) => { if (item == _val) f = true })
 
    return f;
}

export const inArrayValue = (_arr, _val) => {
    let f = 0;
    _arr.map((item) => { if (item == _val) f = item }) 
    return f;
}

export const inArrayValueByKey = (_arr, _key, _val) => {
    let f = null; 
    _arr.map((item) => { if (item[_key] == _val) f = item })
   
    return f;
}
 

export const unCheckInArray = (_arr, _val) => {
    let f = false;
    let _index = 0;
    _arr.map((item,index) => {
        if (item == _val) {
            f = true;
            _index = index;
        }
    })
    if(f) _arr.splice(_index, 1); else _arr.push(_val); 
 
    return _arr;
}
 
export const sumItemValue = (_arr, _key) => {
    let _sum = 0;
    _arr.map((item,index) => {
       _sum = _sum + item[_key];
    })
    return _sum;
}


export const toArrayClearList = (_arr, _key ) => {
    let arr = [];
    _arr.map((item,index) => {
        arr.push({value: item.id, text:  item[_key]})
    }) 
    return arr;
}

export const toArrayList = (_arr ) => {
    let arr = [];
    _arr.map((item,index) => {
        arr.push({value: index, text: item})
    }) 
    return arr;
}

export const toArrayListKey = (_arr , _key) => {
    let arr = [];
    _arr.map((item,index) => {
        arr.push({value: item.id?  item.id : index, text: item[_key] })
    }) 
    return arr;
}

export const toArrayLabelListKey = (_arr , _key) => {
    let arr = [];
    _arr.map((item,index) => {
        arr.push({value: item.id?  item.id : index, label: item[_key] })
    }) 
    return arr;
}

export const toArrayKeyLabelListKey = (_arr , _keyValue, _keyLabel , _empty = false) => {
    let arr = [];
    _arr.map((item,index) => { 
        arr.push({value: item[_keyValue]  ? item[_keyValue] : index, text: item[_keyLabel] })
    })  
    if(_empty)  arr.unshift({value: "all", text: " Все " })
    return arr;
}


export const toArrayImagesListKey = (_arr ) => {
    let arr = [];
    _arr.map((item,index) => {
        arr.push({id: index.toString(), content: item })
    }) 
    return arr;
}

export const fromArrayListKey = (_arr , _key) => {
    let arr = [];
    _arr.map((item,index) => {
        arr.push( item[_key] )
    }) 
    return arr;
}

export const convertImages = (_line) => {
     
   if(_line) return _line.split(","); return [];
}
